@font-face {
  font-family: 'Expressway SemiBold';
  src: url('assets/fonts/Expressway\ SemiBold/Expressway\ SemiBold.ttf') format('truetype');
}
* {
  font-family: 'Expressway SemiBold', sans-serif;

}
a { text-decoration: none;}
.custom-tabs .nav-link {
  color: #000000; /* Set the text color to black */
}

.custom-tabs .nav-link.active {
  color: #0A58CA; /* Set the text color of the active tab to white */
}